<template>
	<div
		v-if="
			($store.getters.mobileSize && showSourceBar) || !$store.getters.mobileSize
		"
		class="tagLeftBarContent"
		:class="{ tagLeftBarContent_mobile: $store.getters.mobileSize }"
		style="box-shadow: 5px 0 5px rgba(0, 0, 0, 0.05)"
	>
		<div
			class="tagLeftBarContentDiv"
			:class="{ IsMobileSize: $store.getters.mobileSize }"
		>
			<div class="cateTitle">
				<p>
					{{
						isEnterpriseView
							? $t("Note.TagLeftBar.Data")
							: $t("Note.TagLeftBar.Note")
					}}
				</p>
			</div>
			<div class="tagAssociation" @click="handleSelect_status('All')">
				<span
					class="tagName d-flex tagName_show"
					:class="{ tagBtnActive: $store.state.selectTagId == 'All' }"
				>
					<i class="fas fa-books me-2"></i>
					<span>{{
						isEnterpriseView
							? $t("Note.TagLeftBar.AllData")
							: $t("Note.TagLeftBar.AllNote")
					}}</span>
					<input
						@click.stop
						value="all"
						v-model="searchAlllocal"
						class="ms-auto selectCheckBox"
						type="checkbox"
					/>
				</span>
			</div>
			<div class="tagAssociation" @click="handleSelect_status('AllWithoutTag')">
				<span
					class="tagName d-flex tagName_show"
					:class="{ tagBtnActive: $store.state.selectTagId == 'AllWithoutTag' }"
				>
					<i class="fad fa-books me-2"></i>
					<span>{{
						isEnterpriseView
							? $t("Note.TagLeftBar.UntaggedData")
							: $t("Note.TagLeftBar.UntaggedNote")
					}}</span>
					<input
						@click.stop
						value="all_noTag"
						v-model="searchAlllocal"
						class="ms-auto selectCheckBox"
						type="checkbox"
					/>
				</span>
			</div>
			<div
				class="tagAssociation"
				index="trash"
				@click="handleSelect_status('trash')"
			>
				<span
					class="tagName d-flex tagName_show"
					:class="{ tagBtnActive: $store.state.selectTagId == 'trash' }"
				>
					<i class="fal fa-trash-alt me-2"></i>
					<span>{{ $t("Note.TagLeftBar.GarbageCan") }}</span>
				</span>
			</div>
			<div class="cateTitle">
				<p>{{ $t("Note.TagLeftBar.Tags") }}</p>
				<p class="ms-auto me-1">{{ $t("Note.TagLeftBar.SelectAll") }}</p>
				<input
					class="selectCheckBox"
					type="checkbox"
					v-model="tagSelectAll"
					:disabled="tagSelectdisabled"
					@change="toggleTagSelectAll"
				/>
			</div>
			<div
				class="tagDiv me-1 border border-top-0 border-start-0 border-end-0"
				style="max-height: 200px; overflow-y: auto"
			>
				<div
					class="tagAssociation"
					v-for="(item, index) in usersTags"
					:key="index"
				>
					<span
						@click.stop="handleSelect(item)"
						class="tagName d-flex tagName_show"
						:class="{
							tagBtnActive:
								$store.state.selectTagId == item.id &&
								!$store.state.isSelectKnowledge,
						}"
					>
						<i class="far fa-tag me-2"></i>
						<span>{{ item.name }}</span>
						<input
							class="ms-auto selectCheckBox"
							type="checkbox"
							:value="item.id"
							v-model="searchTagslocal"
							@click.stop
							:disabled="tagSelectdisabled"
						/>
					</span>
				</div>
			</div>
			<div>
				<div class="tagAssociation" @click="editTagDialogVisible = true">
					<span class="tagName d-flex tagName_show">
						<i class="fa-light fa-pen me-2"></i>
						<span>{{ $t("Note.TagLeftBar.EditTags") }}</span>
					</span>
				</div>
				<div class="tagAssociation" @click="openAddTagDialog()">
					<span class="tagName d-flex tagName_show">
						<i class="fa-light fa-pen me-2"></i>
						<span>{{ $t("Note.TagLeftBar.CreateTag") }}</span>
					</span>
				</div>
			</div>
			<template v-if="!isEnterpriseView">
				<div class="cateTitle">
					<p>知識點</p>
					<p class="ms-auto me-1">全選</p>
					<input
						type="checkbox"
						class="selectCheckBox"
						v-model="pointSelectAll"
						@change="togglePointSelectAll"
					/>
				</div>
				<div
					class="tagDiv me-1 border border-top-0 border-start-0 border-end-0"
					style="max-height: 200px; overflow-y: auto"
				>
					<div
						class="tagAssociation"
						v-for="(item, index) in knowledgePoints"
						:key="index"
					>
						<span
							class="tagName d-flex tagName_show"
							@click="handleSelect_knowledge(item)"
							:class="{
								tagBtnActive:
									$store.state.selectTagId == item.tagId &&
									$store.state.isSelectKnowledge,
							}"
						>
							<i class="fal fa-bullseye-pointer me-2"></i>
							<span> {{ item.title }}</span>

							<div class="ms-auto d-flex">
								<div
									v-if="
										item.sharedMemberId.length != 0 &&
										item.sharedMemberId.some(
											(x) => x != Number(item.createdUser)
										)
									"
								>
									<i
										class="fa-light fa-rectangle-history-circle-user"
										style="color: cadetblue"
									></i>
								</div>
								<input
									class="ms-1 selectCheckBox"
									type="checkbox"
									:value="item.id"
									v-model="searchPointslocal"
									@click.stop
								/>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div class="tagAssociation" @click="manageKnowledgeFunc()">
						<span class="tagName d-flex tagName_show">
							<i class="fa-light fa-pen me-2"></i>
							<span>知識點管理</span>
						</span>
					</div>
				</div>
			</template>
		</div>
	</div>
	<div class="dialog">
		<el-dialog
			v-model="editTagDialogVisible"
			:title="$t('Note.TagLeftBar.EditTags')"
			:width="$store.getters.mobileSize ? '90%' : '600px'"
			center
			@close="resetTagDialog"
		>
			<template v-if="usersTags.length != 0">
				<div class="editTag">
					<div
						v-for="i in usersTags"
						:key="i"
						class="d-flex mb-3 editTagOption"
						:data-tagId="i.id"
						style="font-size: medium"
						@click="renameTag(i.id)"
					>
						<div
							class="d-flex align-items-center justify-content-between w-100"
						>
							<div class="w-100">
								<i
									v-if="renameId == i.id"
									class="trashTag far fa-trash-alt me-2"
									@click.stop.prevent="deleteConfirm(i.id)"
								></i>
								<i v-else class="fa-light fa-tag me-2"></i>
								<input
									class="renameInput"
									:id="'tagId_' + i.id"
									v-if="renameId == i.id"
									type="text"
									:value="i.name"
									style="width: 90%; border: none"
								/>
								<div v-else class="d-inline-block" style="width: 200px">
									{{ i.name }}
								</div>
							</div>
							<div>
								<el-button
									v-if="renameId == i.id"
									circle
									@click.stop.prevent="saveRename(i.id)"
								>
									<i style="width: 14px; height: 14px" class="fal fa-check"></i>
								</el-button>
								<el-button v-else circle>
									<i style="width: 14px; height: 14px" class="fal fa-pen"></i>
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div>{{ $t("Note.TagLeftBar.NoTagsYet") }}</div>
			</template>
			<template #footer>
				<span class="dialog-footer">
					<el-button
						type="primary"
						@click="
							editTagDialogVisible = false;
							saveRename(renameId);
						"
					>
						{{ $t("Note.TagLeftBar.Completed") }}
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "TagLeftBar",
	data() {
		return {
			renameId: "",
			LeftBarContent: false,
			editTagDialogVisible: false,
			// usersTags: [],
			searchPointslocal: [],
			searchTagslocal: [],
			searchAlllocal: ["all"],
			pointSelectAll: false,
			tagSelectAll: false,
			tagSelectdisabled: true,
		};
	},
	props: {
		knowledgePoints: {
			required: true,
		},
		searchPoints: {
			required: true,
		},
		showSourceBar: {
			required: true,
		},
		searchTags: {
			required: true,
		},
		searchAll: {
			required: true,
		},
	},
	methods: {
		openAddTagDialog() {
			this.$emit("openAddTagDialog");
		},
		togglePointSelectAll() {
			if (this.pointSelectAll) {
				this.searchPointslocal = this.knowledgePoints.map((item) => item.id);
			} else {
				this.searchPointslocal = [];
			}
		},
		updateSearchPoints(newValue) {
			let vm = this;
			vm.$emit("update:searchPoints", newValue);
		},
		toggleTagSelectAll() {
			if (this.tagSelectAll) {
				this.searchTagslocal = this.$store.state.usersTags.map(
					(item) => item.id
				);
			} else {
				this.searchTagslocal = [];
			}
		},
		updateSearchTags(newValue) {
			let vm = this;
			vm.$emit("update:searchTags", newValue);
		},
		updateSearchAll(newValue) {
			let vm = this;
			vm.$emit("update:searchAll", newValue);
		},
		manageKnowledgeFunc() {
			let vm = this;
			vm.$emit("manageKnowledge");
		},
		deleteConfirm(tid) {
			let vm = this;

			let confirmMsg = `將刪除這個標籤，並將該標籤從您所有的課程中移除，但您的課程並不會被刪除。`;
			let apiPath = `${process.env.VUE_APP_URL_API}/ClassRoomCenter/RemoveClassTag`;
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				confirmMsg = `將刪除這個標籤，並將該標籤從您所有的筆記中移除，但您的筆記並不會被刪除。`;
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RemoveArticleTag`;
			} else if (vm.$route.name == "ChargeMeContactBook_Note") {
				confirmMsg = `將刪除這個標籤，並將該標籤從您所有的記事本與知識點中移除，但您的記事本與知識點並不會被刪除。`;
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RemoveNoteTag`;
			} else {
				confirmMsg = this.$t("Note.TagLeftBar.Script.DeleteAlert");
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RemoveNoteTag`;
			}

			vm.$confirm(confirmMsg, "", {
				confirmButtonText: this.$t("Note.TagLeftBar.Script.Confirm"),
				cancelButtonText: this.$t("Note.TagLeftBar.Script.Cancel"),
				type: "warning",
			})
				.then(() => {
					var o = {
						TagId: tid,
						NewName: "",
					};
					vm.fetchAPI("post", apiPath, o)
						.then((res) => {
							if (res.status == 200) {
								vm.$store.state.usersTags = vm.$store.state.usersTags.filter(
									(x) => x.id != tid
								);
								vm.$message({
									type: "success",
									message: this.$t("Note.TagLeftBar.Script.DeletingCompleted"),
								});
								vm.$emit("rerenderPage");
							} else {
								vm.$message({
									type: "warning",
									message: this.$t("Note.TagLeftBar.Script.DeletingFailed"),
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {
					return;
				});
		},
		handleSelect_status(text) {
			//編輯不篩選
			let viewText = "";
			if (text == "editTag") return;
			if (text == "All") viewText = "全部筆記";
			if (text == "trash") viewText = "垃圾桶";

			this.$store.state.selectGPTPage = viewText;
			this.$store.state.selectTagId = text;
		},
		handleSelect(tag) {
			//編輯不篩選
			if (tag == "editTag") return;
			this.$store.state.selectGPTPage = "標籤 > " + tag.name;
			this.$store.state.selectTagId = [tag.id];
			this.$store.state.isSelectKnowledge = false;
		},
		handleSelect_knowledge(item) {
			//編輯不篩選
			if (item.tagId == "editTag") return;
			this.$store.state.selectGPTPage = "知識點 > " + item.title;
			this.$store.state.selectTagId = item.tagId;
			this.$store.state.isSelectKnowledge = true;
		},
		renameTag(tagId) {
			let vm = this;
			vm.renameId = tagId;
			setTimeout(() => {
				document.getElementById(`tagId_${tagId}`).focus();
			}, 10);
		},
		saveRename(tagId) {
			let vm = this;
			if (document.getElementById(`tagId_${tagId}`) == null) return;
			let newValue = document.getElementById(`tagId_${tagId}`).value;
			let sameTagNames = vm.usersTags.filter(
				(x) => x.name == newValue && x.id != tagId
			);
			if (sameTagNames.length > 0) {
				vm.$message({
					type: "info",
					message: `已經有重複名稱`,
				});
				return false;
			}

			var o = {
				TagId: tagId,
				NewName: newValue,
			};

			let apiPath = "";
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RenameArticleTag/`;
			} else if (vm.$route.name == "ChargeMeContactBook_Note") {
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RenameNoteTag/`;
			} else {
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RenameNoteTag/`;
			}

			vm.fetchAPI("Post", apiPath, o)
				.then((res) => {
					if (res.status == 200) {
						vm.$message({
							type: "success",
							message: this.$t("Note.TagLeftBar.Script.EditingCompleted"),
						});
						vm.$emit("rerenderPage");
					}
				})
				.catch((err) => {
					console.log(err);
				});

			vm.usersTags.map((x) => {
				if (x.id == tagId) {
					x.name = newValue;
				}
			});
			vm.renameId = null;
		},
	},
	watch: {
		$route: "initial",
		searchPointslocal: function (val) {
			console.log(val);
			let vm = this;

			vm.updateSearchPoints(val);
			// 如果所有項目都被選中，設置 selectAll 為 true，否則為 false
			this.pointSelectAll =
				this.searchPointslocal.length === this.knowledgePoints.length;
		},
		searchAlllocal: function (val) {
			let vm = this;
			let isAll = val.some((x) => x == "all");
			if (isAll) {
				vm.tagSelectdisabled = true;
				vm.searchTagslocal = [];
			} else {
				vm.tagSelectdisabled = false;
			}
			vm.$emit("update:searchAll", val);
		},
		searchTagslocal: function (val) {
			console.log(val);
			let vm = this;
			console.log(2);
			vm.updateSearchTags(val);
			// 如果所有項目都被選中，設置 selectAll 為 true，否則為 false
			console.log("searchTagslocal", this.searchTagslocal.length);
			console.log("usersTags", this.$store.state.usersTags.length);

			this.tagSelectAll =
				this.searchTagslocal.length === this.$store.state.usersTags.length;
		},
	},
	computed: {
		isEnterpriseView() {
			let vm = this;
			return vm.$route.name == "EnterpriseGPTHome";
		},
		usersTags() {
			return this.$store.state.usersTags || [];
		},
	},
	mounted() {
		let vm = this;
		vm.$emit("update:searchAll", ["all"]);
	},
};
</script>

<style lang="scss" scoped>
.selectCheckBox {
	zoom: 1.5;
}
.tagLeftBarContentDiv {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 50px);
	overflow-y: auto;

	&.IsMobileSize {
		height: 95vh !important;
	}
}

.tagLeftBarContent {
	background-color: white;
	position: fixed;
	top: 50px;
	left: 0;
	overflow-y: auto;
	height: 100%;
	width: 250px; /* 假設側邊欄寬度是 250px，根據需要調整 */
	z-index: 9; /* 確保側邊欄位於其他元素之上 */
	text-align: center;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	.cateTitle {
		font-weight: 900;
		font-size: small;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 5px;
		padding-top: 5px;

		background-color: rgba(0, 0, 0, 0.2);
		text-align: start;
		color: black;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	// box-shadow: 5px 0 5px rgba(0, 0, 0, 0.12);
	.tagAssociation {
		display: flex;
		align-content: center;
		width: 100%;
		cursor: pointer;
		.tagName {
			width: 100%;
			padding: 1rem 1rem;
			padding-right: 0.5rem;
			&.tagBtnActive {
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}
	}
}
.tagLeftBarContent_mobile {
	width: 100%;
}
.tagName:hover {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.dialog :deep .el-dialog {
	.el-dialog__body {
		overflow-y: auto;
		max-height: 500px;
	}
}
</style>
